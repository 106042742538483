import ApplicationLogo from '@/Components/ApplicationLogo';
import { Link } from '@inertiajs/react';

export default function Guest({ children }) {
    return (
        <div id="app-bg" className="min-h-screen flex flex-col sm:justify-center items-center pt-6 sm:pt-0 bg-gray-100">


            <div className="w-full sm:max-w-sm mt-0 px-8 py-6 bg-white shadow-md overflow-hidden sm:rounded-lg">
                <div>
                    <Link href="/">
                        <ApplicationLogo className="w-48 h-32 fill-current text-gray-500 mx-auto" />
                    </Link>
                </div>
                {children}
            </div>
        </div>
    );
}
